@charset "utf-8";

/*...VARIABLE...*/
$size: 10px;
$primary: #000000;
$secondary: #666666;
$link: #0173ab;
$bgButton: #0173ab;
$borderLine: #F0F0F0;
$bgFooter: #FFFFFF;
$bgSecondary: #F1F3F7;
$title: #624417;

$darkBrown: #2b1911;
$white: #FFF;
$blue: #0173ab;
$blueLight: #53b8e9;
$orange: #f37f20;
$orangeLight: #ffbe45;
$grey: #B3B3B3;
$green: #75ba97;
$purple: #a785d3;
$black: #000000;
$red: #e77374;
$redDark: #5E051B;
$pink: #eb6494;
$yellowDark: #d3b685;

@import url('https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c&display=swap');

$fontPrimary: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontMincho: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$fontRounded: 'M PLUS Rounded 1c', sans-serif;
$fontMaru: vdl-v7marugothic, sans-serif;